import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import { Grid, withStyles, withTheme } from '@material-ui/core'
import FormControlInput from './FormControlInput'
import classNames from 'classnames'
import FormControlLabel from 'components/Form/FormControlLabel'
import Error from './Error'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { Clear as ClearIcon } from '@material-ui/icons'

import 'react-dates/lib/css/_datepicker.css'
import '../../styles/forms/_datepicker.scss'

const styles = ({ colors }) => {
  return {
    inputContainer: {
      width: '100%',
      cursor: 'pointer'
    },
    inputInput: {
      cursor: 'pointer'
    },
    inputIcon: {
      color: colors.light,
      position: 'absolute',
      right: 10,
      top: 9
    },
    button: {
      padding: 0,
      margin: 0,
      background: 'transparent',
      border: 0,
      outline: 'none',
      position: 'relative'
    },
    timePicker: {
      margin: 10
    },
    formLabelWrapper: {
      marginBottom: '10px'
    },
    formLabel: {
      color: colors.light,
      fontSize: '13px',
      lineHeight: '15px',
      paddingRight: '15px'
    },
    errorTextClass: {
      bottom: '0'
    },
    clearIcon: {
      color: colors.light,
      position: 'absolute',
      top: 6,
      fontSize: '20px',
      right: 30,
      cursor: 'pointer'
    }
  }
}

const changeSources = {
  init: 'init',
  date: 'date',
  timer: 'time'
}

const FormControlDateTimePicker = ({
  classes,
  handleChange = f => f,
  initialValue = '',
  isTime = true,
  format = 'YYYY:MM:DD',
  error = '',
  touched = false,
  label = '',
  customClasses = {},
  classRoot,
  disabled,
  maskValue,
  name,
  fromCurrentDate = true,
  dateTimeDelimiter = ' ',
  position = 'bottom right',
  isFutureDisabled = false,
  timeValueFormat = 'HH:mm',
  theme,
  marginBottom = false,
  isClearable,
  isOptional,
  setInitValue = true,
  preventOverflow,
  initialDate
}) => {
  const [value, setValue] = useState('')
  const [date, setDate] = useState(initialDate || moment())
  const [time, setTime] = useState(
    (initialDate || moment()).format(timeValueFormat)
  )
  const [changeSource, setChangeSource] = useState(changeSources.init)

  useEffect(() => {
    if (initialValue && value !== initialValue) {
      const [d, t] = initialValue.split(dateTimeDelimiter)
      if (!isTime && d) {
        setValue(initialValue)
        setDate(moment(d, format))
      } else if (d && t) {
        setValue(initialValue)
        let newTime = t.slice(0, Math.min(timeValueFormat.length, 8))
        if (timeValueFormat === 'HH:mm' && newTime.slice(-1) === ':') {
          newTime = '0' + newTime.slice(0, timeValueFormat.length - 1)
        }
        setTime(newTime)
        setDate(moment(d, format))
      }
      setChangeSource(changeSources.init)
    }
    // eslint-disable-next-line
  }, [initialValue])

  const changeValue = useCallback(() => {
    if (isTime && time) {
      const [hours, minutes, seconds] = time.split(':')

      const currentDate = date.clone()

      currentDate.hours(hours).minutes(minutes)

      if (seconds) {
        currentDate.seconds(seconds)
      }

      setValue(currentDate.format(format))
    } else {
      setValue(date.format(format))
    }
  }, [isTime, time, setValue, format, date])

  const handleDateChange = useCallback(
    e => {
      setDate(e)
      if (!setInitValue && changeSource === changeSources.init) {
        changeValue()
      }
      setChangeSource(changeSources.date)
    },
    [setDate, changeSource, changeValue, setInitValue]
  )

  useEffect(() => {
    if (setInitValue) {
      changeValue()
    } else if (changeSource !== changeSources.init) {
      changeValue()
    }
    // eslint-disable-next-line
  }, [date])

  useEffect(() => {
    if (value) {
      changeValue()
    }
    // eslint-disable-next-line
  }, [time])

  useEffect(() => {
    if (
      value &&
      value !== initialValue &&
      (setInitValue || changeSource !== changeSources.init)
    ) {
      if (!name) {
        handleChange(value)
      } else {
        handleChange({ target: { value, name } })
      }
    }
    // eslint-disable-next-line
  }, [value])

  const dayPickerDate = useMemo(() => {
    if (setInitValue) {
      return date
    }
    if (!(initialValue && date)) {
      return null
    }

    return initialValue && date
  }, [date, initialValue, setInitValue])

  return (
    <Grid container className={classRoot}>
      <FormControlLabel
        label={label}
        isOptional={isOptional}
        rootClassName={customClasses.labelClass}
      />
      <MaterialPopup
        on="click"
        placement={position}
        disabled={disabled}
        preventOverflow={preventOverflow}
        trigger={
          <Grid
            container
            className={classNames(classes.button, customClasses.buttonClass)}
            onClick={() => {}}
          >
            <FormControlInput
              fullWidth
              type="text"
              value={initialValue ? value : ''}
              marginBottom={marginBottom}
              formControlContainerClass={classes.inputContainer}
              formControlInputClass={classes.inputInput}
              disabled={disabled}
              icon={
                <i
                  className={classNames(
                    'fa-regular fa-calendar-days',
                    classes.inputIcon
                  )}
                />
              }
              touched={touched}
              {...(initialValue && { maskValue })}
            />
            {initialValue && isClearable && (
              <ClearIcon
                className={classes.clearIcon}
                onClick={e => {
                  e.stopPropagation()
                  setValue('')
                  handleChange({ target: { value: null, name } })
                  setChangeSource(changeSources.init)
                }}
              />
            )}
          </Grid>
        }
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          className={classNames({
            'DatePicker-dark': theme.type === 'dark'
          })}
        >
          <DayPickerSingleDateController
            date={dayPickerDate}
            hideKeyboardShortcutsPanel
            onDateChange={handleDateChange}
            isOutsideRange={day =>
              fromCurrentDate
                ? isFutureDisabled
                  ? moment().diff() < 0
                  : moment()
                      .startOf('day')
                      .diff(moment(day).startOf('day'), 'days') > 0
                : false
            }
            focused
          />

          {isTime && (
            <FormControlInput
              formControlContainerClass={classes.timePicker}
              marginBottom={false}
              type="time"
              value={time}
              handleChange={e => {
                setTime(e.target.value)
                setChangeSource(changeSources.time)
              }}
            />
          )}
        </Grid>
      </MaterialPopup>
      <Error
        absolute={false}
        condition={!!error}
        error={error}
        rootClassName={classes.errorTextClass}
      />
    </Grid>
  )
}

FormControlDateTimePicker.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  initialValue: PropTypes.string,
  format: PropTypes.string,
  isTime: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dateTimeDelimiter: PropTypes.string,
  isClearable: PropTypes.bool,
  isOptional: PropTypes.bool,
  setInitValue: PropTypes.bool,
  preventOverflow: PropTypes.object,
  initialDate: PropTypes.object
}

export default withTheme(withStyles(styles)(FormControlDateTimePicker))
