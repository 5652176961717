import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import Icon from 'components/Icons/Icon'
import Text from 'components/Typography/Text'

const styles = () => ({
  noPreviewDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '180px',
    position: 'relative',
    backgroundImage: 'linear-gradient(#000, #7f7f7f)',
    '&:hover': {
      backgroundImage: 'linear-gradient(#000, #000)'
    },
    '&:hover $noPhoto': {
      opacity: 0,
      display: 'none'
    },
    '&:hover $noScreenshotText': {
      opacity: 1
    }
  },
  noPhoto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '90px',
    height: '100%',
    opacity: 1
  },
  noScreenshotText: {
    position: 'absolute',
    color: '#fff',
    transition: 'opacity .25s .03s',
    opacity: 0,
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    padding: '0 20px',
    userSelect: 'none'
  },
  portraitImgBlock: {
    height: 320,
    '& > p': {
      left: 0,
      textAlign: 'center'
    }
  }
})

const NoPreviewDiv = ({ t, isPortraitImg = false, classes, rootClassName }) => {
  return (
    <div
      className={classNames(classes.noPreviewDiv, rootClassName, {
        [classes.portraitImgBlock]: isPortraitImg
      })}
    >
      <Icon
        icon="fa-regular fa-camera-slash"
        color="light"
        className={classes.icon}
        rootClassName={classes.noPhoto}
      />
      <Text
        variant={'big'}
        color={'light'}
        rootClassName={classes.noScreenshotText}
      >
        {t('Device Playback Preview is not available at this time.')}
      </Text>
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(NoPreviewDiv))
