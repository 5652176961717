import React, { Fragment } from 'react'
import Form from 'components/Account/SignIn/Form'
import IpAddress from 'components/Account/SignIn/IpAddress'

export default function SignInContent({
  displayIp,
  role,
  onSubmit,
  isLoading,
  sso,
  error
}) {
  return (
    <Fragment>
      <Form
        isIpVisible={displayIp}
        role={role}
        sso={sso}
        isLoading={isLoading}
        onSubmit={onSubmit}
        error={error}
      />
      {displayIp && <IpAddress />}
    </Fragment>
  )
}
