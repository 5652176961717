import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Typography, withStyles, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'

import { FormControlInput } from 'components/Form'
import Spacing from 'components/Containers/Spacing'
import SubmitButton from './SubmitButton'
import PasswordVisibilityButton from './PasswordVisibilityButton'
import { requiredField, emailField } from 'constants/validationMessages'
import { passwordValidateSchema } from 'constants/validations'
import { instanceTypes, ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import SocialPanel from '../SocialLogin/SocialPanel'
import { simulateEvent } from 'utils'

function styles() {
  return {
    inputWrap: {
      position: 'relative',
      marginBottom: '16px'
    },
    formControlInput: {
      height: '50px',
      fontSize: '18px'
    },
    formControlContainer: {
      marginBottom: '10px'
    },
    formLink: {
      margin: '0 14px 0 3px',
      display: 'inline-block',
      textDecoration: 'none'
    },
    formLinkText: {
      fontSize: '13px',
      color: '#0076b9'
    },
    forgotPassLink: {
      top: '16px',
      right: '12px'
    },
    checkboxSwitcherLabel: {
      fontSize: '14px'
    },
    submitWrap: {
      marginBottom: 30
    },
    submitWrapWithIp: {
      marginBottom: '20px'
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    dontHaveAccountText: {
      color: '#888996',
      textAlign: 'center',
      marginTop: 30
    },
    dontHaveAccountLink: {
      color: '#0076b9'
    },
    eyeContentWrap: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 3,
      right: 8
    },
    signInContainer: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    alignBaseline: {
      alignItems: 'baseline'
    },
    submitButtonRoot: {
      width: '100%',
      maxWidth: 320,
      marginRight: 10
    }
  }
}

const formConfig = {
  initialValues: {
    username: '',
    password: '',
    rememberMe: false
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required(requiredField).email(emailField),
    password: passwordValidateSchema
  })
}

const { initialValues, validationSchema } = formConfig

const isProduction = process.env.REACT_APP_INSTANCE === instanceTypes.prod

function Form({ t, role, sso, classes, isIpVisible, isLoading, onSubmit }) {
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [loginWithAnotherMethod, setLoginWithAnotherMethod] = useState(false)

  const {
    isValid,
    values,
    touched,
    errors,
    handleChange,
    validateForm,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ username, password, rememberMe }) => {
      onSubmit(username, password, rememberMe, role)
    }
  })

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  const handleInputChange = useCallback(
    e => {
      const { value, name } = e.target
      if (value) {
        handleChange(simulateEvent(name, value.replaceAll(' ', '')))
      } else {
        handleChange(e)
      }
    },
    [handleChange]
  )

  const signInButton = useMemo(
    () => (
      <SubmitButton
        opaque={!isValid}
        isLoading={isLoading}
        rootClass={role === SYSTEM_ROLE && classes.submitButtonRoot}
      >
        {t('Sign In')}
      </SubmitButton>
    ),
    [isValid, isLoading, role, t, classes.submitButtonRoot]
  )

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputWrap}>
        <FormControlInput
          id="username"
          type="text"
          autocomplete="email"
          name="username"
          value={values.username}
          handleChange={handleInputChange}
          error={errors.username}
          handleBlur={handleBlur}
          touched={touched.username}
          placeholder={t('Email')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
      </div>
      <div className={classes.inputWrap}>
        <FormControlInput
          id="password"
          type={passwordVisibility ? 'text' : 'password'}
          name="password"
          value={values.password}
          handleChange={handleInputChange}
          error={errors.password}
          handleBlur={handleBlur}
          touched={touched.password}
          placeholder={t('Password')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
        <div className={classes.eyeContentWrap}>
          <PasswordVisibilityButton
            onClick={() => setPasswordVisibility(!passwordVisibility)}
            isVisible={passwordVisibility}
          />
          <Link
            to={
              role === SYSTEM_ROLE
                ? '/system/forgot-password'
                : '/forgot-password'
            }
            component={RouterLink}
            className={classNames(classes.formLink, classes.forgotPassLink)}
          >
            <Typography className={classes.formLinkText}>
              {t('Reset Password')}
            </Typography>
          </Link>
        </div>
      </div>
      <Spacing
        variant={0}
        rootClassName={classNames({
          [classes.submitWrap]: !isIpVisible,
          [classes.submitWrapWithIp]: isIpVisible
        })}
      >
        <Spacing variant={0}>
          <Grid
            container
            className={classNames(classes.signInContainer, {
              [classes.alignBaseline]:
                !loginWithAnotherMethod && role !== SYSTEM_ROLE
            })}
          >
            <SocialPanel
              renderOptions={sso}
              role={role}
              loginWithAnotherMethod={loginWithAnotherMethod}
              setLoginWithAnotherMethod={setLoginWithAnotherMethod}
              signInButton={signInButton}
            />
          </Grid>
        </Spacing>
        {isProduction && role === ORG_ROLE && (
          <Spacing>
            <Typography className={classes.dontHaveAccountText}>
              {t('Want to try?')}{' '}
              <Link
                to="/sign-up"
                component={RouterLink}
                className={classes.dontHaveAccountLink}
              >
                {t('Sign up for a free trial!')}
              </Link>
            </Typography>
          </Spacing>
        )}
      </Spacing>
    </form>
  )
}

Form.propTypes = {
  role: PropTypes.string,
  sso: PropTypes.object,
  isIpVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default withTranslation('translations')(withStyles(styles)(Form))
