import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Card from '../Card'
import DeviceInfoRow from './DeviceInfoRow'
import Spacing from 'components/Containers/Spacing'
import Text from 'components/Typography/Text'
import DevicePreviewRefreshIcon from 'components/Icons/DevicePreviewRefreshIcon'
import GreyCard from 'components/Card/GreyCard'
import useDevicePreviews from 'hooks/api/useDevicePreviews'
import DevicePreviewLoader from 'components/Loaders/DevicePreviewLoader'
import { truncateLength } from '../consts'
import { insetColons } from 'utils/macAddressUtils'
import { DateTimeView } from 'components/TableLibrary'
import useDeviceHealth from 'hooks/api/useDeviceHealth'
import { TextWithTooltip } from 'components/Typography'
import DeviceMoreInfoCardLoader from 'components/Loaders/DeviceMoreInfoCardLoader'
import { getDevicePreviewUrl } from 'utils/deviceUtils'
import NoPreviewDiv from './NoPreviewDiv'
import RectLoader from 'components/Loaders/RectLoader'
import DeviceSecurityIcon from 'components/DeviceSecurityIcon'
import DeviceRamViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceRamViewCell'

const popupSizeByOrientation = {
  landscape: { height: 620, width: 362 },
  portrait: { height: 465, width: 620 }
}

const useStyles = makeStyles(({ palette, type, typography }) => ({
  cardRoot: ({ isPortraitImg }) => ({
    padding: 0,
    borderRadius: '7px',
    height: isPortraitImg
      ? popupSizeByOrientation.portrait.height
      : popupSizeByOrientation.landscape.height,
    width: isPortraitImg
      ? popupSizeByOrientation.portrait.width
      : popupSizeByOrientation.landscape.width
  }),
  moreInfoCardHeader: {
    padding: '0 20px',
    marginBottom: 0,
    borderBottom: `solid 1px ${palette[type].deviceCard.border}`,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '8px 8px 0 0'
  },
  moreInfoCardHeaderText: {
    ...typography.darkAccent[type],
    lineHeight: '45px'
  },
  refreshIcon: {
    paddingBottom: 2
  },
  portraitImageRefreshIcon: {
    padding: 4,
    fontSize: 16
  },
  loaderWrapper: ({ isPortraitImg }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: isPortraitImg ? 620 : 362,
    height: isPortraitImg ? 465 : 620
  }),
  capacity: {
    ...typography.darkAccent[type],
    textAlign: 'right'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 10px 20px'
  },
  portraitImageContainer: {
    flexDirection: 'row',
    gap: 30,
    padding: '12px 20px'
  },
  portraitImageBlockWrapper: {
    width: 281,
    height: 375,
    marginTop: 10
  },
  detailsBlockWrapper: {
    width: '100%'
  },
  dateContentWrap: {
    display: 'flex',
    alignItems: 'baseline'
  },
  imageBlock: {
    objectFit: 'contain',
    position: 'relative',
    width: '100%',
    height: 180
  },
  portraitImageBlock: {
    height: 320,
    width: 180
  },
  devicePortraitInfoRow: {
    minHeight: 36
  },
  devicePortraitDetailLabel: {
    lineHeight: 1
  },
  updatedLabelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 2px 0 2px',
    alignItems: 'center'
  },
  dateTime: {
    fontSize: '0.75rem !important',
    marginLeft: '2px !important'
  },
  dateTimeRoot: {
    padding: '2px 2px 3px 2px',
    justifyContent: 'start'
  },
  fontWeightNormal: {
    fontWeight: '400 !important'
  },
  securityIcon: {
    fontSize: 15
  }
}))

const DeviceMoreInfoCard = ({
  t,
  device,
  setIsLoading,
  isPortraitImg = false,
  setIsPortraitImg = () => {}
}) => {
  const classes = useStyles({ isPortraitImg })

  const [initialized, setInitialized] = useState(false)
  const [imageState, setImageState] = useState({ loading: true, error: false })

  const { data, isPreviewPending, updatePreview } = useDevicePreviews(device.id)

  const {
    activeMacAddress,
    memory,
    isPending: isHealthPending
  } = useDeviceHealth(device.id)

  const imagePreview = useMemo(() => getDevicePreviewUrl(data), [data])

  const memoryInfo = useMemo(() => {
    let res = {}
    const { storageUtilization, memoryUtilization } = memory
    if (memoryUtilization) {
      const freeSpace = (memoryUtilization.freeSpace / 1024).toFixed(2)
      const totalSpace = (
        (memory.memoryUtilization.freeSpace +
          memory.memoryUtilization.usageSpace) /
        1024
      ).toFixed(2)
      const memoryUtilizations = totalSpace - freeSpace
      res.ram = {
        freeSpace,
        totalSpace,
        memoryUtilizations
      }
    }
    if (storageUtilization) {
      if (storageUtilization?.freeSpace && storageUtilization?.usageSpace) {
        res.hardDisk = t('Hard Disk Capacity', {
          freeSpace: (storageUtilization.freeSpace / 1024).toFixed(2) + ' GB',
          totalSpace:
            (
              (storageUtilization.freeSpace + storageUtilization.usageSpace) /
              1024
            ).toFixed(2) + ' GB'
        })
      }
    }
    return res
  }, [memory, t])

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isPreviewPending || isHealthPending || !initialized)
    }
    // eslint-disable-next-line
  }, [isPreviewPending, isHealthPending, initialized])

  const handleUpdate = useCallback(() => {
    updatePreview(device.id)
  }, [device.id, updatePreview])

  const handleImageError = useCallback(
    () => setImageState({ loading: false, error: true }),
    []
  )

  const handleImageLoad = useCallback(
    e => {
      try {
        const { naturalWidth, naturalHeight } = e.target
        setIsPortraitImg(naturalHeight > naturalWidth)
        setImageState({ loading: false, error: false })
      } catch (e) {
        handleImageError()
      }
    },
    [handleImageError, setIsPortraitImg]
  )

  useEffect(() => {
    if (isHealthPending) {
      setInitialized(true)
    }
  }, [isHealthPending])

  return isHealthPending || !initialized ? (
    <div className={classes.loaderWrapper}>
      <DeviceMoreInfoCardLoader
        isPortraitImg={isPortraitImg}
        height={
          isPortraitImg
            ? popupSizeByOrientation.portrait.height
            : popupSizeByOrientation.landscape.height
        }
      />
    </div>
  ) : (
    <Card
      icon={device?.osVersion}
      title={device.name}
      iconButtonComponent={
        <DeviceSecurityIcon
          device={device}
          iconRootClass={classes.securityIcon}
          hide={!isEmpty(device?.virtualDevice)}
          withStatus={false}
        />
      }
      rootClassName={classes.cardRoot}
      headerClasses={[classes.moreInfoCardHeader]}
      headerTextClasses={[classes.moreInfoCardHeaderText]}
    >
      <div
        className={classNames(classes.container, {
          [classes.portraitImageContainer]: isPortraitImg
        })}
      >
        <GreyCard
          header={false}
          contentSpacingProps={{
            paddingVert: 0,
            paddingHor: 0
          }}
          rootClassName={classNames({
            [classes.portraitImageBlockWrapper]: isPortraitImg
          })}
        >
          <Spacing variant={0}>
            {isPreviewPending ? (
              <DevicePreviewLoader height={isPortraitImg ? 320 : 180} />
            ) : imagePreview && !imageState.error ? (
              <>
                <img
                  className={classNames(classes.imageBlock, {
                    [classes.portraitImageBlock]: isPortraitImg
                  })}
                  src={imagePreview}
                  alt="preview"
                  onError={handleImageError}
                  onLoad={handleImageLoad}
                />
                {(imageState.loading || isPreviewPending) && (
                  <RectLoader position="absolute" inset={0} />
                )}
              </>
            ) : (
              <NoPreviewDiv isPortraitImg={isPortraitImg} />
            )}
            {!isPortraitImg ? (
              <Spacing
                variant={0}
                paddingHor={1}
                paddingVert={1}
                direction="row"
                component="footer"
                justify="space-between"
                alignItems="center"
              >
                <div className={classes.dateContentWrap}>
                  <Text variant={'small'}>{`${t('Updated At')}:`}</Text>
                  <DateTimeView
                    date={data.lastUpdate}
                    withSeparator
                    direction="row"
                    reversedRow
                  />
                </div>
                <DevicePreviewRefreshIcon
                  deviceId={device.id}
                  onUpdate={handleUpdate}
                  iconClass={classes.refreshIcon}
                />
              </Spacing>
            ) : (
              <>
                <div className={classes.updatedLabelWrapper}>
                  <Text rootClassName={classes.dateTime}>
                    {`${t('Updated At')}:`}
                  </Text>
                  <DevicePreviewRefreshIcon
                    deviceId={device.id}
                    onUpdate={handleUpdate}
                    iconClass={classes.portraitImageRefreshIcon}
                  />
                </div>

                <DateTimeView
                  date={data.lastUpdate}
                  rootClassName={classes.dateTimeRoot}
                  textClass={classes.dateTime}
                  textSmallClass={classes.dateTime}
                  withSeparator
                  direction="row"
                  reversedRow
                />
              </>
            )}
          </Spacing>
        </GreyCard>
        <div className={classes.detailsBlockWrapper}>
          <DeviceInfoRow
            title="MAC Address"
            isTruncate={insetColons(device.activeMacAddress) > truncateLength}
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            {insetColons(device.activeMacAddress) ||
              insetColons(activeMacAddress)}
          </DeviceInfoRow>

          <DeviceInfoRow
            title="Device LAN IP"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            {device.lanIP}
          </DeviceInfoRow>

          <DeviceInfoRow
            title="Device WAN IP"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            {device.wanIP}
          </DeviceInfoRow>

          <DeviceInfoRow
            title="RAM"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
            customValueType
          >
            <DeviceRamViewCell
              memory={device.memoryUtilization}
              osVersion={device.osVersion}
              totalMemory={device.totalMemory}
              avgMemory={device.avgMemory}
              memoryAverageUsage={memory.memoryUtilization.averageUsage}
              memoryTotalSpace={memoryInfo.ram.totalSpace}
              memoryUtilization={memoryInfo.ram.memoryUtilizations}
            />
          </DeviceInfoRow>
          <DeviceInfoRow
            title="Storage"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
            customValueType
          >
            {memoryInfo && memoryInfo.hardDisk && (
              <Grid item>
                <Typography className={classes.capacity}>
                  {memoryInfo.hardDisk}
                </Typography>
              </Grid>
            )}
          </DeviceInfoRow>
          <DeviceInfoRow
            title="Processors"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            <TextWithTooltip
              weight="bold"
              color="title.primary"
              maxWidth={170}
              component="span"
            >
              {device.processor}
            </TextWithTooltip>
          </DeviceInfoRow>
          <DeviceInfoRow
            title="OS Version"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            {device.osVersion}
          </DeviceInfoRow>
          <DeviceInfoRow
            title="Browser Version"
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            {device.browserVersion}
          </DeviceInfoRow>

          <DeviceInfoRow
            title="Description"
            hasCorner={false}
            {...(isPortraitImg && {
              rootClassName: classes.devicePortraitInfoRow,
              detailLabelClassName: classes.devicePortraitDetailLabel
            })}
          >
            <TextWithTooltip
              weight="bold"
              color="title.primary"
              maxWidth={200}
              component="span"
            >
              {device.description}
            </TextWithTooltip>
          </DeviceInfoRow>
        </div>
      </div>
    </Card>
  )
}

export default withTranslation('translations')(DeviceMoreInfoCard)
