import React from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import classNames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'

import Card from '../Card'
import { TextWithTooltip } from 'components/Typography'
import { Checkbox } from 'components/Checkboxes'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { WhiteButton } from 'components/Buttons'
import LibraryTypeIcon from 'components/LibraryTypeIcon'

const styles = ({ palette, type, typography, lineHeight, fontWeight }) => ({
  moreInfoIconButton: {
    opacity: 0,
    transition: 'opacity 0.5s'
  },
  cardRoot: {
    padding: 0,
    border: `solid 1px ${palette[type].deviceCard.border}`,
    boxShadow: `0 2px 4px 0 ${palette[type].deviceCard.shadow}`,
    borderRadius: '7px',
    transitionDuration: '3s',

    '&:hover': {
      '& $moreInfoIconButton': {
        opacity: 1
      },
      '& $cardTitleWrapper': {
        maxWidth: '80%',
        opacity: 1
      },
      '& $cardActionRoot': {
        opacity: 1
      },
      '& $cardFooter': {
        height: palette[type].card.footer.height
      },
      '& $footerCheckbox': {
        opacity: 1
      },
      '& $previewRoot': {
        height: 135
      },
      '& $restFooterRoot': {
        opacity: 1
      }
    }
  },
  disableHover: {
    '& $moreInfoIconButton': {
      opacity: 1,
      display: 'block'
    },
    '& $cardTitleWrapper': {
      maxWidth: '80%',
      opacity: 1
    },
    '& $cardActionRoot': {
      opacity: 1
    },
    '& $cardFooter': {
      height: palette[type].card.footer.height
    },
    '& $footerCheckbox': {
      opacity: 1
    },
    '& $previewRoot': {
      height: 135
    },
    '& $restFooterRoot': {
      opacity: 1
    }
  },
  cardHeader: {
    padding: '10px 20px 5px',
    marginBottom: 0,
    backgroundColor: palette[type].templateCard.header.background,
    borderRadius: '7px 7px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: palette[type].card.greyHeader.height,
    overflow: 'hidden'
  },
  cardHeaderText: {
    ...typography.darkAccent[type],
    fontSize: '16px',
    lineHeight: lineHeight.big,
    fontWeight: fontWeight.bold,
    textTransform: 'capitalize'
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  cardTitleWrapper: {
    maxWidth: '100%',
    opacity: 0.5,
    transitionProperty: 'opacity, max-width',
    transitionDuration: '0.3s'
  },
  cardFooter: {
    height: 0,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette[type].templateCard.footer.background,
    borderRadius: '0 0 7px 7px',
    transition: 'height 0.5s'
  },
  cardFooterVisible: {
    height: palette[type].card.footer.height
  },
  footerCheckbox: {
    marginRight: '10px',
    opacity: 0,
    transition: 'opacity 0.5s'
  },
  actionDropdown: {
    overflow: 'hidden'
  },
  cardActionRoot: {
    opacity: 0,
    transition: 'opacity 0.5s'
  },
  cardActionBtn: {
    minWidth: '32px',
    paddingLeft: '5px',
    paddingRight: '5px',
    boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',
    color: '#0a83c8',

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#f5f6fa'
    }
  },
  cardActionBtnIcon: {
    width: 18,
    height: 18
  },
  cardActionList: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionBtnLink: {
    flexDirection: 'column',
    minWidth: '100px',
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,

    '&:not(:last-child)': {
      borderRight: `1px solid ${palette[type].dropdown.borderColor}`
    }
  },
  actionBtnIconWrap: {
    margin: '15px 0'
  },
  actionBtnIcon: {
    fontSize: '24px',
    color: '#74809a'
  },
  actionBtnText: {
    fontSize: '12px',
    color: '#74809a',
    whiteSpace: 'pre'
  },
  titleIconWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  moreInfoMenuDropdownContainer: {
    width: '360px'
  },
  faded: {
    opacity: `0.5 !important`
  },
  cardTitleIcon: {
    fontSize: 22
  },
  visible: {
    opacity: 1
  },
  cardHeaderWrap: {
    flexWrap: 'nowrap'
  },
  previewRoot: {
    textAlign: 'center',
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '240px',
    height: '190px',
    cursor: 'pointer',
    position: 'relative',
    transition: 'height 0.5s',
    borderRadius: 0
  },
  previewRootNormal: {
    height: 135
  },
  previewIcon: {
    fontSize: 64
  },
  restFooterRoot: {
    display: 'flex',
    opacity: 0,
    transition: 'opacity 0.5s'
  }
})

const LibraryGridCard = ({
  title = '',
  titleIcon,
  titleIconClassName,
  iconButtonComponent,
  titleComponent,
  titleIconRender: TitleIconRender,
  restTitleComponent,
  restFooterComponent,
  menuDropdownComponent,
  cardFooterComponent,
  cardRootClassName,
  titleComponentWrapClassName,
  menuDropdownContainerClassName,
  cardHeaderClassName,
  headerIconBtnClassName,
  cardFooterClassName,
  actionRootClassName,
  headerPopupPlacement = 'bottom',
  headerPopupOn = 'hover',
  isSelected = false,
  isFaded = false,
  onCardClick,
  selectable = true,
  withShowMore = true,
  withFooter = true,
  cardActions = [],
  handleSelect = f => f,
  defaultPreview = false,
  previewColor = '#fff',
  previewIcon,
  previewIconHelperClass,
  onPreview = f => f,
  previewRootClassName,
  children,
  classes,
  disableHoverEffect,
  popupKeepTooltipInside = 'body',
  maxTitleWidth,
  dropdown = true,
  customCardAction
}) => {
  return (
    <Card
      rootClassName={classNames(
        classes.cardRoot,
        { [classes.disableHover]: disableHoverEffect },
        cardRootClassName
      )}
      headerClasses={[classes.cardHeader, cardHeaderClassName]}
      titleComponentWrapClassName={classNames(
        classes.cardTitleWrapper,
        titleComponentWrapClassName,
        {
          [classes.visible]: isSelected
        }
      )}
      headerWrapClassName={classes.cardHeaderWrap}
      titleComponent={
        titleComponent || (
          <div className={classNames(classes.cardTitle)}>
            <Grid item className={classes.titleIconWrap}>
              {TitleIconRender ? (
                <TitleIconRender
                  className={classNames(
                    titleIconClassName,
                    classes.cardTitleIcon
                  )}
                />
              ) : (
                <i
                  className={classNames(
                    titleIcon,
                    titleIconClassName,
                    classes.cardTitleIcon
                  )}
                />
              )}
            </Grid>
            <TextWithTooltip
              rootClassName={classNames(classes.cardHeaderText, {
                [classes.faded]: isFaded
              })}
              maxWidth={maxTitleWidth || 170}
            >
              {title}
            </TextWithTooltip>
            {restTitleComponent && restTitleComponent}
          </div>
        )
      }
      iconButtonComponent={iconButtonComponent}
      dropdown={dropdown}
      icon={withShowMore}
      menuDropdownContainerClassName={classNames(
        classes.moreInfoMenuDropdownContainer,
        menuDropdownContainerClassName
      )}
      menuDropdownComponent={menuDropdownComponent}
      popupPlacement={headerPopupPlacement}
      on={headerPopupOn}
      iconButtonClassName={classNames(
        classes.moreInfoIconButton,
        headerIconBtnClassName
      )}
      {...(onCardClick && { onClick: onCardClick })}
    >
      {defaultPreview ? (
        <LibraryTypeIcon
          color={previewColor}
          icon={previewIcon}
          iconHelperClass={classNames(
            previewIconHelperClass,
            classes.previewIcon
          )}
          wrapHelperClass={classNames(
            classes.previewRoot,
            previewRootClassName,
            {
              [classes.previewRootNormal]: isSelected
            }
          )}
          onClick={onPreview}
        />
      ) : (
        children
      )}
      {withFooter && (
        <footer
          className={classNames(classes.cardFooter, cardFooterClassName, {
            [classes.cardFooterVisible]: isSelected
          })}
        >
          {cardFooterComponent || (
            <Grid container justify="space-between" alignItems={'center'}>
              <Grid item>
                <Grid container alignItems="center">
                  {selectable && (
                    <Grid
                      item
                      className={classNames(classes.footerCheckbox, {
                        [classes.visible]: isSelected
                      })}
                      onClick={handleSelect}
                    >
                      <Checkbox checked={isSelected} />
                    </Grid>
                  )}
                  {restFooterComponent && (
                    <Grid item className={classes.restFooterRoot}>
                      {restFooterComponent}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!!cardActions.find(({ render }) => render) && (
                <Grid
                  item
                  className={classNames(
                    classes.cardActionRoot,
                    actionRootClassName
                  )}
                >
                  <MaterialPopup
                    trigger={
                      <WhiteButton className={classes.cardActionBtn}>
                        <Settings className={classes.cardActionBtnIcon} />
                      </WhiteButton>
                    }
                  >
                    <List
                      component="nav"
                      disablePadding={true}
                      className={classes.cardActionList}
                    >
                      {cardActions.map(
                        (
                          {
                            icon,
                            iconComponent,
                            label,
                            clickAction,
                            disabled,
                            render,
                            to,
                            iconClassName
                          },
                          index
                        ) => (
                          <React.Fragment key={`grid-card-action-${index}`}>
                            {render && (
                              <ListItem
                                button
                                className={classes.actionBtnLink}
                                onClick={clickAction}
                                component={to && RouterLink}
                                to={to}
                                disabled={disabled}
                              >
                                <ListItemIcon
                                  className={classes.actionBtnIconWrap}
                                >
                                  {iconComponent ? (
                                    iconComponent
                                  ) : (
                                    <i
                                      className={classNames(
                                        icon,
                                        classes.actionBtnIcon,
                                        iconClassName
                                      )}
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={label}
                                  classes={{ primary: classes.actionBtnText }}
                                />
                              </ListItem>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </List>
                  </MaterialPopup>
                </Grid>
              )}

              {!!customCardAction && (
                <Grid
                  item
                  className={classNames(
                    classes.cardActionRoot,
                    actionRootClassName
                  )}
                >
                  {customCardAction}
                </Grid>
              )}
            </Grid>
          )}
        </footer>
      )}
    </Card>
  )
}

export default withStyles(styles)(LibraryGridCard)
