import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Button, Tooltip } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { withSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { _get } from 'utils/lodash'

import { closeMediaPreview, clearMediaPreview } from 'actions/mediaActions'
import {
  closePlaylistPreview,
  clearPlaylistPreview
} from 'actions/playlistActions'
import {
  closeTemplatePreview,
  clearTemplatePreview
} from 'actions/templateActions'
import {
  closeSchedulePreview,
  clearSchedulePreview
} from 'actions/scheduleActions'

import {
  clearMenuDesignPreview,
  closeMenuDesignPreview
} from 'actions/menuDesignActions'
import useWindowDimensions from 'hooks/useWindowDimensions'
import useEscKeyDownListener from 'hooks/useEscKeyDownListener'
import { isString } from 'utils/generalUtils'
import usePreviewModalFeatures from 'hooks/usePreviewModalDimentions'
import { PreviewSettingsContext } from 'components/Media/MediaPreviewSettingsContext'
import PreviewModalBase from 'components/Modal/PreviewModalBase'
import {
  FLASH_DURATION,
  landscapeDefaultDimensions,
  portraitDefaultDimensions
} from 'constants/previewModal'
import { COLOR_PICKER_MODAL_ID } from 'components/Modal/ColorPickerModal/constants.js'
import chessSvg from 'common/assets/patterns/chess.svg'
import { mediaNotificationExceptions } from 'constants/media'

const closePreview = {
  media: closeMediaPreview,
  playlist: closePlaylistPreview,
  template: closeTemplatePreview,
  schedule: closeSchedulePreview,
  menuDesign: closeMenuDesignPreview
}
const clearPreview = {
  media: clearMediaPreview,
  playlist: clearPlaylistPreview,
  template: clearTemplatePreview,
  schedule: clearSchedulePreview,
  menuDesign: clearMenuDesignPreview
}

const backgroundModes = {
  opaque: 'opaque',
  dark: 'dark',
  light: 'light'
}

const useStyles = makeStyles(({ palette, type }) => ({
  frameRoot: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${chessSvg})`,
    backgroundRepeat: 'round',
    visibility: 'hidden'
  },
  [`frameRoot-${backgroundModes.dark}`]: {
    background: 'black'
  },
  [`frameRoot-${backgroundModes.light}`]: {
    background: 'white'
  },
  frameRootVisible: {
    visibility: 'visible'
  },
  checkBoardHide: {
    backgroundImage: 'none'
  },
  frameFullScreen: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none'
  },
  controlsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette[type].card.greyHeader.color,
    padding: '2px',
    transition: 'opacity .25s ease, color .25s ease',
    height: '20px',
    width: '20px',
    '& svg': {
      height: 20,
      width: 20
    },
    '& i': {
      fontSize: 20
    },
    '&.draggable-handle': {
      cursor: 'move',
      '&:hover': {
        cursor: 'move'
      }
    },
    '&:hover, &.is-active': {
      cursor: 'pointer',
      color: '#3f51b5'
    },
    '&.is-disable': {
      color: 'inherit',
      opacity: '0.5',
      cursor: 'default !important'
    }
  },
  lastControlItem: {
    padding: '2.5px',
    '& svg': {
      width: '16px',
      height: '16px'
    }
  },
  lightIcon: {
    position: 'relative'
  },
  blackIcon: {
    color: 'black',
    position: 'absolute',
    left: 0,
    right: 0,
    animation: '$opacity linear 4s infinite'
  },
  whiteIcon: {
    color: 'white',
    position: 'absolute',
    left: 0,
    right: 0,
    animation: '$opacity linear 4s infinite reverse'
  },
  '@keyframes opacity': {
    '0%, 60%': {
      opacity: 0
    },
    '70%': {
      opacity: 1
    },
    '80%, 100%': {
      opacity: 0
    }
  }
}))

const controlPopupClassName = [
  'xhibit-MuiPopover-root',
  'xhibit-MuiDialog-root',
  'snackbarContainer'
]

const ScreenPreviewModal = ({
  t,
  enqueueSnackbar,
  closeSnackbar,
  theme,
  targetReducer = 'media',
  bounds = 'parent',
  actionKey,
  backDropClass
}) => {
  const {
    isRefreshRequired,
    setRefreshRequired,
    handleShowPreview
  } = useContext(PreviewSettingsContext)
  const iframeRef = useRef(null)
  const [isResizing, setResizing] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [dimensions, setDimensions] = useState(landscapeDefaultDimensions)
  const [oldDimensions, setOldDimensions] = useState(null)
  const [modalPosition, setModalPosition] = useState({})
  const [iframeSrc, setIframeSrc] = useState(null)
  const [isIframeReady, setIframeReady] = useState(false)
  const [showIframe, setShowIframe] = useState(false)
  const [isClosing, setClosing] = useState(false)
  const [themeSettingsOpen, setThemeSettingsOpen] = useState(false)
  const [backgroundMode, setBackgroundMode] = useState(backgroundModes.opaque)
  const [resizingPosition, setResizingPosition] = useState({ x: 0, y: 0 })
  const classes = useStyles()
  const dispatchAction = useDispatch()
  const windowDimensions = useWindowDimensions()
  const { getNewDimensions } = usePreviewModalFeatures()
  const {
    isVisible: visible,
    isLoading,
    response,
    error,
    meta,
    id: previewId,
    key,
    orientation,
    resolution
  } = useSelector(state => state[targetReducer].preview)
  //hide
  const isVisible = useMemo(() => {
    return actionKey ? key === actionKey && visible : !key && visible
  }, [visible, actionKey, key])

  const setModalCenter = useCallback(
    sizes => {
      if (!sizes) sizes = dimensions
      const { width, height } = sizes
      const x = (windowDimensions.width - width) / 2
      const y = (windowDimensions.height - height) / 2
      setModalPosition({ x, y })
    },
    [dimensions, windowDimensions.height, windowDimensions.width]
  )

  const setIframeStyles = useCallback(() => {
    if (isVisible && isIframeReady && iframeRef.current) {
      const iframeContent = iframeRef.current?.contentWindow
      iframeContent &&
        iframeContent.document.head.insertAdjacentHTML(
          'beforeend',
          `<style>#vimeoIframe, iframe {width: 100%; height: 100%}</style>`
        )
    }
  }, [isIframeReady, isVisible])

  const handleFullScreen = useCallback(() => {
    const body = document.querySelector('body')

    if (isFullScreen) {
      body.style.overflowY = 'auto'
      setDimensions(oldDimensions)
      setOldDimensions(null)
    } else {
      body.style.overflowY = 'hidden'
      setOldDimensions(dimensions)
      const { innerWidth, innerHeight } = window
      setDimensions({
        width: innerWidth,
        height: innerHeight
      })
    }

    setFullScreen(!isFullScreen)
  }, [dimensions, isFullScreen, oldDimensions])

  const handleModalClose = useCallback(() => {
    setClosing(true)
    setTimeout(() => {
      if (isFullScreen) handleFullScreen()
      setIframeReady(false)

      if (iframeSrc) {
        URL.revokeObjectURL(iframeSrc)
      }
      setIframeSrc(null)

      dispatchAction(clearPreview[targetReducer]())
      dispatchAction(closePreview[targetReducer]())
      setClosing(false)
    }, FLASH_DURATION)
  }, [dispatchAction, handleFullScreen, iframeSrc, isFullScreen, targetReducer])

  const toggleBackgroundMode = useCallback(() => {
    setBackgroundMode(prevState => {
      const modes = Object.values(backgroundModes)
      const currentIndex = modes.findIndex(value => value === prevState)
      const nextIndex = currentIndex === modes.length - 1 ? 0 : currentIndex + 1

      return modes[nextIndex]
    })
  }, [])

  const toggleOrientation = useCallback(() => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight

    const isLandscape = dimensions.width > dimensions.height
    const nextDimensions = getNewDimensions(
      isLandscape ? portraitDefaultDimensions : landscapeDefaultDimensions,
      true
    )
    const nextModalPosition = { x: 0, y: 0 }

    if (screenWidth > nextDimensions.width) {
      nextModalPosition.x = Math.floor((screenWidth - nextDimensions.width) / 2)
    }

    if (screenHeight > nextDimensions.height) {
      nextModalPosition.y = Math.floor(
        (screenHeight - nextDimensions.height) / 2
      )
    }

    setDimensions(nextDimensions)
    setModalPosition(nextModalPosition)
  }, [getNewDimensions, dimensions])

  const handleWindowResize = useCallback(() => {
    const newDimensions = {
      width: Math.min(dimensions.width, window.innerWidth),
      height: Math.min(dimensions.height, window.innerHeight)
    }

    setDimensions(newDimensions)
    setModalPosition({
      x: window.innerWidth / 2 - newDimensions.width / 2,
      y: window.innerHeight / 2 - newDimensions.height / 2
    })
  }, [dimensions])

  useEscKeyDownListener(handleModalClose)

  const checkBoardPattenOnError = useMemo(() => {
    try {
      if (!response) return false

      const scriptStart = response.indexOf('window.renderPreview(')
      const scriptEnd = response.indexOf(');', scriptStart)
      const jsonString = response.slice(
        scriptStart + 'window.renderPreview('.length,
        scriptEnd
      )
      const jsonObject = JSON.parse(jsonString)
      const contentObject = jsonObject.content

      return !!contentObject?.targetModel?.errors
    } catch (error) {
      return false
    }
  }, [response])

  useEffect(() => {
    if (isFullScreen) {
      const { width, height } = windowDimensions
      setDimensions({ width, height })
    }
    // eslint-disable-next-line
  }, [windowDimensions])

  useEffect(() => {
    setModalCenter()
    // eslint-disable-next-line
  }, [isFullScreen])

  useEffect(() => {
    const isLandscape = orientation
      ? orientation.toLowerCase() === 'landscape'
      : dimensions.width > dimensions.height
    const nextDimensions = getNewDimensions(
      isLandscape ? landscapeDefaultDimensions : portraitDefaultDimensions,
      true
    )

    const size = isFullScreen ? dimensions : nextDimensions
    setDimensions(size)
    setModalCenter(size)

    if (isLoading) return
    if (response && iframeRef.current) {
      let contentDocument = iframeRef.current.contentWindow.document
      contentDocument.open()
      contentDocument.write(response)
      contentDocument.close()
    }

    if (error && !mediaNotificationExceptions.includes(error.exception)) {
      handleModalClose()
      const errorFields = _get(error, 'errorFields', [])
      if (errorFields.length) {
        errorFields.forEach(({ name, value }) => {
          if (!meta?.ignoreSnackbarFields?.includes(name)) {
            value.forEach(message => {
              enqueueSnackbar(message, {
                variant: 'error',
                action: key => (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => closeSnackbar(key)}
                  >
                    {t('OK')}
                  </Button>
                )
              })
            })
          }
        })
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: key => (
            <Button
              color="secondary"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              {t('OK')}
            </Button>
          )
        })
      }
    }
    // eslint-disable-next-line
  }, [response, error, isLoading])

  useEffect(() => {
    setIframeSrc(null)
    // eslint-disable-next-line
  }, [previewId])

  useEffect(() => {
    if (isFullScreen) {
      setModalCenter()
    }
    // eslint-disable-next-line
  }, [dimensions])

  useEffect(() => {
    if (isVisible && isIframeReady) {
      setIframeStyles()
    }
    // eslint-disable-next-line
  }, [isIframeReady, isVisible])

  useEffect(() => {
    const newDimensions = getNewDimensions(landscapeDefaultDimensions)
    setDimensions(newDimensions)
    setModalCenter(newDimensions)
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      setRefreshRequired(false)
    },
    // eslint-disable-next-line
    [isVisible]
  )
  useEffect(
    () => {
      if (isIframeReady) {
        setTimeout(() => {
          setShowIframe(true)
        }, FLASH_DURATION)
      } else {
        setShowIframe(false)
      }
    },
    // eslint-disable-next-line
    [isIframeReady]
  )

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize])

  const updatedModalPosition = useMemo(() => {
    if (isResizing) {
      return {
        x: modalPosition.x - resizingPosition.x,
        y: modalPosition.y - resizingPosition.y
      }
    }

    return modalPosition
  }, [modalPosition, resizingPosition, isResizing])

  const onClickAway = useCallback(
    e => {
      const elements = e.composedPath ? e.composedPath() : e.path
      const isControlPopup = (elements || []).some(({ className }) => {
        return (
          isString(className) &&
          controlPopupClassName.find(v => className.includes(v))
        )
      })

      const isColorPicker = (elements || []).some(({ id }) => {
        return id === COLOR_PICKER_MODAL_ID
      })

      if (
        !isResizing &&
        !isControlPopup &&
        !isColorPicker &&
        elements.length > 3
      )
        handleModalClose()
    },
    [handleModalClose, isResizing]
  )

  const controls = useMemo(() => {
    return (
      <>
        {!isFullScreen && (
          <div className={classNames(classes.controlsItem, 'draggable-handle')}>
            <Tooltip arrow title={t('Move')}>
              <i className="fa-solid fa-grip-dots-vertical" />
            </Tooltip>
          </div>
        )}
        <Tooltip
          arrow
          title={t('Change background color (affects preview only)')}
        >
          <div
            className={classNames(classes.controlsItem, classes.lightIcon, {
              'is-disable': !showIframe
            })}
            onClick={toggleBackgroundMode}
          >
            <i className="fa-solid fa-moon-over-sun fa-fade" />
            <i
              className={classNames(
                'fa-solid',
                'fa-moon-over-sun',
                classes.blackIcon
              )}
            />
            <i
              className={classNames(
                'fa-solid',
                'fa-moon-over-sun',
                classes.whiteIcon
              )}
            />
          </div>
        </Tooltip>
        {(!resolution || resolution === 'x') &&
          !isFullScreen &&
          targetReducer !== 'template' && (
            <div
              className={classNames(classes.controlsItem)}
              onClick={toggleOrientation}
            >
              <Tooltip arrow title={t('Change orientation')}>
                <i className="fa-duotone fa-group-arrows-rotate fa-beat-fade" />
              </Tooltip>
            </div>
          )}
        <div
          className={classNames(classes.controlsItem)}
          onClick={() => handleFullScreen()}
        >
          <Tooltip arrow title={t(`${isFullScreen ? 'Collapse' : 'Maximize'}`)}>
            <i
              className={`fa-sharp fa-solid ${
                isFullScreen ? 'fa-minimize' : 'fa-maximize'
              }`}
            />
          </Tooltip>
        </div>

        <div
          className={classNames(classes.controlsItem, classes.lastControlItem)}
          onClick={() => handleModalClose()}
        >
          <Tooltip arrow title={t('Close')}>
            <i className="fa-duotone fa-power-off" />
          </Tooltip>
        </div>
      </>
    )
  }, [
    t,
    classes,
    handleFullScreen,
    handleModalClose,
    isFullScreen,
    showIframe,
    toggleBackgroundMode,
    toggleOrientation,
    targetReducer,
    resolution
  ])

  const content = useMemo(() => {
    return (
      <iframe
        className={classNames(
          classes.frameRoot,
          classes[`frameRoot-${backgroundMode}`],
          {
            [classes.frameFullScreen]: isFullScreen,
            [classes.frameRootVisible]: showIframe,
            [classes.checkBoardHide]: checkBoardPattenOnError
          }
        )}
        title="screenPreviewModal"
        ref={iframeRef}
        src={iframeSrc}
        onLoad={() => setIframeReady(true)}
        frameBorder="0"
      />
    )
  }, [
    classes,
    iframeSrc,
    isFullScreen,
    backgroundMode,
    showIframe,
    checkBoardPattenOnError
  ])

  const handleResizeStart = useCallback(() => {
    setResizing(true)
  }, [])

  const handleResize = useCallback(
    (e, direction, ref, d) => {
      if (direction === 'top' || direction === 'topRight') {
        setResizingPosition({
          x: 0,
          y: modalPosition.y - d.height >= 0 ? d.height : modalPosition.y
        })
      } else if (direction === 'left' || direction === 'bottomLeft') {
        setResizingPosition({
          x: modalPosition.x - d.width >= 0 ? d.width : modalPosition.x,
          y: 0
        })
      } else if (direction === 'topLeft') {
        setResizingPosition({
          x: modalPosition.x - d.width >= 0 ? d.width : modalPosition.x,
          y: modalPosition.y - d.height >= 0 ? d.height : modalPosition.y
        })
      }
    },
    [modalPosition]
  )

  const handleResizeStop = useCallback(
    (e, direction, ref, d) => {
      const newWidth = dimensions.width + d.width
      const newHeight = dimensions.height + d.height
      setDimensions({ width: newWidth, height: newHeight })

      setModalPosition({
        x: modalPosition.x - resizingPosition.x,
        y: modalPosition.y - resizingPosition.y
      })
      setResizingPosition({ x: 0, y: 0 })

      setResizing(false)
    },
    [dimensions, modalPosition, resizingPosition]
  )

  return (
    <PreviewModalBase
      isVisible={isVisible}
      isLoading={isLoading}
      isFullScreen={isFullScreen}
      controls={controls}
      content={content}
      isContentReady={!!(isVisible && isIframeReady && !showIframe)}
      isClosing={isClosing}
      targetReducer={targetReducer}
      onClickAway={onClickAway}
      setModalPosition={setModalPosition}
      updatedModalPosition={updatedModalPosition}
      bounds={bounds}
      dimensions={dimensions}
      onResizeStart={handleResizeStart}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      themeSettingsOpen={themeSettingsOpen}
      setThemeSettingsOpen={setThemeSettingsOpen}
      isRefreshRequired={isRefreshRequired}
      handleShowPreview={handleShowPreview}
      backDropClass={backDropClass}
    />
  )
}

export default withTranslation('translations')(
  withSnackbar(withTheme(memo(ScreenPreviewModal)))
)
