import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import Footer from './Footer'
import BackgroundImage from 'common/assets/images/sign-in.webp'
import Scrollbars from 'components/Scrollbars'
import moment from 'moment'
import { getBaseUrl } from 'utils/apiUtils'

function styles({ palette, type, spacing }) {
  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
      width: '100vw',
      height: '100vh',
      background: `url("${BackgroundImage}") no-repeat`,
      backgroundSize: 'cover'
    },
    formWrap: {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      left: '120px',
      zIndex: 2,
      width: '640px',
      padding: '25px 0px 25px 35px',
      background: palette[type].pages.singIn.background,
      transform: 'translateY(-50%)'
    },
    formWrapSm: {
      display: 'flex',
      alignItems: 'center',
      inset: 0,
      transform: 'none',
      width: '100%'
    },
    formContent: {
      marginBottom: 20,
      paddingTop: 75,
      paddingRight: 35
    },
    formContentXs: {
      paddingTop: 0,
      marginBottom: 0
    },
    backgroundImage: {
      opacity: 0,
      position: 'relative',
      overflow: 'hidden',
      width: '100vw',
      height: '100vh',
      backgroundSize: 'cover',
      transition: 'opacity 0.3s'
    }
  }
}

const TOTAL_IMAGES = 4
const startFromDate = moment('2022').startOf('isoWeek')

const imageNumber =
  (moment().startOf('isoWeek').diff(startFromDate, 'weeks') - 1) %
    TOTAL_IMAGES || TOTAL_IMAGES

function AccountModal({
  classes,
  rootClassName,
  formWrapClassName,
  formContentClassName,
  children,
  denseFooter,
  hideDynamicImages = false
}) {
  const [backgroundStyles, setBackgroundStyles] = useState({})
  const matchesSm = useMediaQuery('(max-width:800px)')
  const matchesXs = useMediaQuery('(max-width:576px)')

  useEffect(() => {
    if (!hideDynamicImages) {
      const image = new Image()
      image.src = `${getBaseUrl()}assets/cms/images/loginPage/CMS-Login-Image-${imageNumber}.webp`
      image.onload = () => {
        setBackgroundStyles(prevState => ({
          ...prevState,
          opacity: 1,
          backgroundImage: `url(${image.src})`
        }))
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classNames(classes.root, rootClassName)}>
      <div className={classes.backgroundImage} style={backgroundStyles} />
      <div
        className={classNames(classes.formWrap, formWrapClassName, {
          [classes.formWrapSm]: matchesSm
        })}
      >
        <Scrollbars autoHeight autoHeightMax={'calc(100vh - 110px)'}>
          <div
            className={classNames(classes.formContent, formContentClassName, {
              [classes.formContentXs]: matchesXs
            })}
          >
            {children}
            <Footer dense={denseFooter} />
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

AccountModal.propTypes = {
  classes: PropTypes.object,
  rootClassName: PropTypes.string,
  children: PropTypes.node,
  hideDynamicImages: PropTypes.bool
}

export default withStyles(styles)(AccountModal)
