import React, { memo, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'

import FormControlCopyInput from 'components/Form/FormControlCopyInput'
import DefaultModal from 'components/Modal/DefaultModal'
import { getBaseUrl } from 'utils/apiUtils'

const getVirtualDeviceLink = subdomain => {
  if (!subdomain || !getBaseUrl()) {
    return
  }

  try {
    return `https://vp-${subdomain}.${new URL(getBaseUrl()).host}/playback`
  } catch (e) {}
}

const DeviceVirtualPlayerModal = ({
  device: { virtualDevice },
  open = false,
  handleClose = f => f,
  t
}) => {
  const virtualDeviceLink =
    getVirtualDeviceLink(virtualDevice?.subdomain) || t('N/A')

  //const wanIPLink = wanIP ? `https://${wanIP}/playback` : t('N/A')

  const handleCloseModal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <DefaultModal
      modalTitle={t('Virtual Player')}
      onCloseModal={handleCloseModal}
      open={open}
      maxWidth="xs"
      hasSaveBtn={false}
    >
      <FormControlCopyInput
        label={t('Virtual Device Link')}
        value={virtualDeviceLink}
        disabled={!virtualDevice?.subdomain}
      />
    </DefaultModal>
  )
}

export default compose(
  withTranslation('translations'),
  memo
)(DeviceVirtualPlayerModal)
