import api from './api'

import { errorHandler } from 'utils'

const getOauth2Tokens = async (oauth2Feature, params = {}) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/oauth2',
      params: {
        ...params,
        ...(oauth2Feature && { feature: oauth2Feature })
      }
    })

    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const postOauth2Token = async (oauth2Feature, code, name) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/oauth2/${oauth2Feature}`,
      data: {
        code,
        name
      }
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteOauth2Token = async ({ tokenId, data }) => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/oauth2/${tokenId}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const oauth2Service = {
  getOauth2Tokens,
  postOauth2Token,
  deleteOauth2Token
}

export default oauth2Service
