import { createAppApi } from '../services/api'

export const authenticationApi = createAppApi('authenticationQuery', {
  endpoints: builder => ({
    recovery: builder.mutation({
      query: ({ role, ...data }) => ({
        method: 'POST',
        url: `${role}/recovery`,
        data
      })
    })
  })
})

export const { useRecoveryMutation } = authenticationApi
